<template>
  <CRow>
    <CCol lg="12">
      <CCard>
        <CCardHeader>
          <slot name="header">
            <strong>Im Tarif nicht erhältlich</strong>
          </slot>
        </CCardHeader>
        <CCardBody>
          <h3>Digitalisieren Sie Ihre Immobilien-Akquise noch mehr mit weniger Aufwand</h3>
          <h3>– mit der Marketing Cloud Premium</h3>
          <p>
            Verkürzen Sie manuelle Aufgaben, um mehr Zeit zu haben, um sich um Deal-Abschlüss und das Wachstum Ihres Unternehmens zu kümmern.<br />
            Zur Nutzung dieser Funktion benötigen Sie den Tarif <strong>Professional</strong>. Aktuell haben Sie jedoch den Tarif <strong>{{ $commons.getCurrentPlan().name }}</strong> gebucht.
          </p>
          <CButton type="button" color="success" href="mailto:info@makler-anfragen-immo?subject=Upgrade%20auf%20den%20Professional%20Tarif&body=Ich%20m%C3%B6chte%20die%20Marketing-Cloud%20Professional%20mieten%2C%20bitte%20kontaktieren%20Sie%20mich.">Marketing Cloud Professional – <b>Upgrade</b></CButton>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'PlanForbidden',
  data() {
    return {
      featureName: this.$route.params.featureName || '',
      requiredPlan: this.$route.params.requiredPlan || ''
    }
  }
}
</script>

<style scoped>

</style>
